<template>
  <div>

    <v-dialog v-model="taksitDialog" eager max-width="1650" persistent>
      <v-card>
  
        <v-card-text>
          <v-tabs color="secondary" v-model="currentTab">
            <v-tab>Taksit Listesi</v-tab>
            <v-tab-item>
              <v-divider class="mt-1"></v-divider>
              <v-card>
                <v-card-text>
                  <v-data-table
                    :headers="taksitHeaders"
                    :items="taksitItems"
                    :items-per-page="500"
                    :loading="loading"
                    hide-default-footer
                    :item-class="row_classes"    
                  >         
                   <!-- :item-class="row_classes" Css ile renklendirme - GMZ (Servisten gelen değeri kullanıyor)  -->            
                  <template v-slot:item="{ item }">
                    <tr :style="{ backgroundColor: item.color }">
                      <td>{{ item.posname }}</td>
                      <td> 
                         <v-img class="rounded d-flex align-center justify-center"
                        width="150" height="30" 
                        :src="item.poslogo">
                       </v-img>
                      </td>
                      <td>{{ item.stext }}</td>
                      <td>{{ item.instcount }}</td>
                      <td>{{ item.instrate }}</td>
                      <td>{{ item.instamount }}</td>
                      <td>{{ item.instCekilen }}</td>
                      <td>{{ item.instHesabaGecen }}</td>                    
                    </tr>
                  </template>                  

                 <!--  <template #[`item.poslogo`]="{ item }">
                    <v-img class="rounded d-flex align-center justify-center"
                     width="150" height="30" 
                     :src="item.poslogo">
                    </v-img>

                  </template>   -->           
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-tab-item>         
          </v-tabs>
         
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="taksitDialog = false" color="error">KAPAT</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog v-model="dialog" eager width="500" persistent>
      <v-btn color="error" block @click="paymentCancel">
        <v-icon left>{{ icons.mdiClose }}</v-icon>
        KAPAT
      </v-btn>
      <div id="credit"></div>
    </v-dialog>

    <v-row>      
      <v-col v-if="status" class="d-block d-md-flex justify-center pt-2 pb-6 py-md-16">
        <v-progress-circular v-if="pageLoading" class="el-center" :size="120" indeterminate>
          <span class="text-caption">Yükleniyor</span>
        </v-progress-circular>

        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card
            v-if="!pageLoading"
            elevation="16"
            :rounded="$vuetify.breakpoint.mdAndUp ? 'xl' : 'lg'"
            :width="$vuetify.breakpoint.mdAndUp ? 900 : '100%'"
          >
            <v-card-title class="grey">
              <v-icon :size="$vuetify.breakpoint.mdAndUp ? '60' : '30'" left>
                {{ icons.mdiContactlessPayment }}
              </v-icon>
              <span :class="$vuetify.breakpoint.mdAndUp ? 'text-h5' : 'text-body-1'">Serbest Ödeme</span>
              <v-spacer />
            </v-card-title>
            <customer-list :styleTheme="true" v-if="(userDataRole.role === 'PLSYR' || userDataRole.role === 'FIN' ) && $vuetify.breakpoint.xsOnly" />

            <v-divider />
            <v-container class="pa-6">
              <v-row align="center" style="min-height: 80px">
                <v-col cols="12" md="5" class="d-flex align-center justify-space-between"> </v-col>
                <v-col cols="12" md="7">
                  <v-item-group v-model="odemeTipi" mandatory>
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-item v-slot="{ active, toggle }">
                          <v-card
                            :color="active ? 'secondary' : 'nightDark'"
                            class="d-flex align-center justify-center"
                            dark
                            height="75"
                            @click="toggle"
                          >
                            <div class="text-body-1" :class="active ? 'font-weight-medium white--text' : 'text--secondary'">
                              <span class="text-center"> Toplam Borç </span>
                            </div>
                          </v-card>
                        </v-item>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-item v-slot="{ active, toggle }">
                          <v-card
                            :color="active ? 'secondary' : 'nightDark'"
                            class="d-flex align-center justify-center"
                            dark
                            height="75"
                            @click="toggle"
                          >
                            <div class="text-body-1" :class="active ? 'font-weight-medium white--text' : 'text--secondary'">
                              <span class="text-center"> Serbest Tutar </span>
                            </div>
                          </v-card>
                        </v-item>
                      </v-col>
                    </v-row>
                  </v-item-group>
                </v-col>
              </v-row>
              <v-row v-if="odemeTipi === 0">
                <v-col>
                  <v-data-table
                    :headers="headers"
                    :items="desserts"
                    :items-per-page="-1"
                    hide-default-footer
                    hide-default-header
                  >
                    <template #[`item.name`]="{ item }">
                      <span
                        v-if="
                          item.name === 'Toplam Risk' || item.name === 'Risk Limiti' || item.name === 'Kalan Açık Tutar'
                        "
                        class="font-weight-bold"
                      >
                        {{ item.name }}
                      </span>
                      
                      <span v-else class="-block d-md-flex justify-end pt-2 pb-6 py-md-6" >
                        {{ item.name }}
                      </span>
                    </template>
                    <template #[`item.tutar`]="{ item }">
                      {{ item.tutar | currency }}
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col cols="12" md="5"></v-col>
                <v-col cols="12" md="7">
                  <v-text-field
                    v-model="manuelTutar"
                    hide-details
                    :rules="[v => !!v || 'Item is required']"
                    label="Ödenecek Tutar"
                    filled
                    rounded
                    class="rounded"
                    suffix="TL"
                  ></v-text-field>
                </v-col>
              </v-row>
              <div v-if="paymentError" class="px-3 pt-3">
                <v-alert prominent type="error">
                  <div>Ödeme Başarısız</div>
                  <div class="text-caption">
                    {{ paymentErrorMsg }}
                  </div>
                </v-alert>
              </div>
              <div>
                <CardForm
                  :form-data="formData"
                  @input-card-number="updateCardNumber"
                  @input-card-name="updateCardName"
                  @input-card-month="updateCardMonth"
                  @input-card-year="updateCardYear"
                  @input-card-cvv="updateCardCvv"
                />
              </div>

              <v-row v-if="taksitlendirme.installment">
                <v-col cols="12">
                  <v-item-group v-model="taksitSecim" mandatory>
                    <v-row dense>
                      <v-col v-for="taksit in taksitlendirme.installment" :key="taksit.id" cols="6" md="2">
                        <v-item v-slot="{ active, toggle }">
                          <v-card
                            :color="active ? 'secondary' : 'nightDark'"
                            class="d-flex align-center justify-center"
                            dark
                            height="75"
                            @click="toggle"
                          >
                            <div class="text-body-1" :class="active ? 'font-weight-medium white--text' : 'black--text'">
                              <span v-if="taksit.installment_text === 'Tek Çekim'" class="text-center">
                                {{ taksit.installment_text }}
                              </span>
                              <span v-else> {{ taksit.installment_text.charAt(0) }} Taksit </span>
                            </div>
                          </v-card>
                        </v-item>
                      </v-col>
                    </v-row>
                  </v-item-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="12" md="5"></v-col>
                <v-col cols="12" md="7">
                  <v-card class="rounded-lg" flat color="nightDark">
                    <div class="d-flex align-center justify-space-between">
                      <v-card-title>
                        <div class="text-caption text-md-body-1 font-weight-medium">Ödenecek Tutar</div>
                        <div v-if="taksitSayisi != 'T' && taksitSayisi != 0" class="text-caption">
                          ( {{ taksitTutar | currency }} x {{ taksitSayisi }} )
                        </div>
                      </v-card-title>

                      <v-card-title class="justify-end">
                        <v-chip color="secondary" label :large="$vuetify.breakpoint.mdAndUp">
                          {{ toplamOdenecekTutar | currency }}
                        </v-chip>
                      </v-card-title>
                    </div>
                  </v-card>
                  
                  <v-btn color="accent" x-small depressed block @click="openTaksit()" >                   
                      Taksit Seçenekleri                   
                  </v-btn>

                  <v-row v-if="odemeTipi !== 0">
                    <v-col cols="12" md="12"></v-col>
                    <v-col cols="12" md="12">
                      <v-textarea
                        v-model="ltext"
                      
                        label="Açıklama"
                        filled
                        
                        rows="15"
                        class="scrollable-textarea"
                     
                        maxlength="200"
                       :rules="[value => (value.length <= 200 || true || 'Maksimum 200 karakter girebilirsiniz.')]"
                      ></v-textarea>
                      <div class="textarea-info">
                        <div class="textarea-counter">{{ ltext.length }} / 200</div>
                        <div v-if="ltext.length >= 200" class="error-message">
                          Maksimum 200 karakter girebilirsiniz.
                        </div>
                      </div>
                    </v-col>
                  </v-row>

                  <v-btn color="error" x-large depressed block class="mt-6 white--text" @click="submit">
                    Ödeme Yap
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-form>
      </v-col>
      <v-col cols="12" v-else class="mt-3">
        <customer-list v-if="(userDataRole.role === 'PLSYR' || userDataRole.role === 'FIN' ) && $vuetify.breakpoint.xsOnly" />
        <v-alert text outlined class="mx-3 mx-lg-0 rounded-pill mt-2 mt-md-0 text-caption" type="warning">
          Lütfen yukarıdan bir müşteri seçin
        </v-alert>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CardForm from '@/components/CardForm.vue'
import CustomerList from '@/components/CustomerList.vue'
import store from '@/store'
import useAppConfig from '@core/@app-config/useAppConfig'
import { useRouter } from '@core/utils'
import { mdiAccountCircle, mdiAt, mdiCalendar, mdiContactlessPayment, mdiLock, mdiPhone, mdiUpdate } from '@mdi/js'
import { postData } from '@utils'
import { onMounted, ref, watch } from '@vue/composition-api'
import Vue from 'vue'
import orderStoreModule from '@/views/order/orderStoreModule'
export default {
  components: {
    CardForm,
    CustomerList,
  },
  setup() {
    const { menuIsVerticalNavMini } = useAppConfig()
    const odemeTipi = ref(0)
    const { uid } = JSON.parse(localStorage.getItem('userData'))
    const kredikartino = ref('')
    const kredikartiadsoyad = ref('')
    const kredikartiinstallment = ref([])
    const kredikartiay = ref('')
    const kredikartiyil = ref('')
    const kredikarticvv = ref('')

    const toplamOdenecekTutar = ref(0)
    const longText = ref(0)
    const valid = ref(true)
    const kkAdSoyad = ref(null)
    const kkAy = ref(null)
    const kkYil = ref(null)
    const kkCvv = ref(null)
    const kkNo = ref(null)
    const selectedInstallment = ref(null)
    const bankaKodu = ref(null)
    const cardFamily = ref(null)
    const cardBankName = ref(null)

    const checkbox1 = ref(false)
    const checkbox2 = ref(false)
    const checkbox3 = ref(false)
    const taksitlendirme = ref({})
    const taksitSecim = ref(null)
    const taksitTutar = ref(0)
    const taksitSayisi = ref(0)
    const dialog = ref(false)
    const form = ref(null)

    const { router } = useRouter()
    const userData = ref({})
    const loading = ref(true)
    const userDataRole = JSON.parse(localStorage.getItem('userData'))
    const status = ref(false)
    const pageLoading = ref(true)
    const desserts = ref([])
    const taksitDialog = ref(false)
    const currentTab = ref(0)
    const taksitItems = ref([])
    
    const PRODUCT_APP_STORE_MODULE_NAME = 'app-order'
    // Register module
    if (!store.hasModule(PRODUCT_APP_STORE_MODULE_NAME)) {
          store.registerModule(PRODUCT_APP_STORE_MODULE_NAME, orderStoreModule)
        }

    const taksitHeaders = [
      {
        text: '',
        value: 'posname',
        sortable: false,
        width: '50px',
      },
      {
        text: '',
        value: 'poslogo',
        width: '120px',
      },
      {
        text: 'Açıklama',
        value: 'stext',
        width: '100px',
      },
      {
        text: 'Taksit',
        value: 'instcount',
        width: '20px',
      },
      {
        text: 'Oran',
        value: 'instrate',
        width: '20px',
      },
      {
        text: 'Taksit Tutarı',
        value: 'instamount',
        width: '40px',
      },
      {
        text: 'Çekilecek Tutar',
        value: 'instCekilen',
        width: '40px',
      },
      {
        text: 'Hesaba Geçecek',
        value: 'instHesabaGecen',
        width: '40px',
      },
    
    ]

    onMounted(() => {
      if ((userDataRole.role === 'PLSYR' || userDataRole.role === 'FIN') && store.state.plasiyerCustomer === null) {
        status.value = false
      } else {
        status.value = true
        fetchBalance()
      }
    })
    watch(
      () => store.state.plasiyerCustomer,
      () => {

       

        pageLoading.value = true
        if (store.state.plasiyerCustomer) {
          status.value = true
          fetchBalance()
        }
        else if (store.state.plasiyerCustomer === null)
        {
          console.log('plasiyerCustomer NULL mi? = ' + store.state.plasiyerCustomer)
          userData.value = []
          status.value = false
          
        }
      },
    )

    watch(
      () => taksitDialog.value,
      () => {
        debugger
        if (taksitDialog.value == false) {
          form.value.resetValidation()
          //kod.value = ''
          //taksit_sayisi.value = ''
          //stext.value = ''
          //vade_orani.value = ''
         // sistem_vade_orani.value = ''
          //vade_gun_sayisi.value = ''
          //SECTIONdb_vade_gun_sayisi.value = ''
          //SECTIONkanal.value = ''
          //taksitType.value = 'add'
        }
      },
    )

    watch(
      () => currentTab.value,
      () => {
        if (currentTab.value == 0) {
          loading.value = true
          fetchTaksit()
        }
      },
    )

    const fetchTaksit = () => {
      store
        .dispatch('postMethod', {
          method: 'getAllInstallments',
          amount: toplamOdenecekTutar.value,
          paymentDiffMode:1,
          encode:'no'
        })
        .then(response => {
          //response.detail[0]["color"]='#466a95'
          //console.log("response",response)
          taksitItems.value = response.detail
          //taksitItems.value[0]["color"]='#466a95'
         // console.log("taksitItems",taksitItems.value)
          loading.value = false
        })
    }

    const openTaksit = () => {
      taksitDialog.value = true
      currentTab.value = 0
      loading.value = true
      fetchTaksit()
    }

    const fetchBalance = () => {
      const method = 'getCustomerBalanceDetailed'
      let customer
      if (router.currentRoute.params.id) {
        customer = router.currentRoute.params.id
      } else {
        customer = uid
      }

      store
        .dispatch('postMethod', { method, customer })
        .then(response => {
          if (response.error === 0) {
            userData.value = response.balance[0]
            toplamOdenecekTutar.value = response.balanceDetail.head[0].TBLVAL
            /*   -1 * (Number(response.balanceDetail.head[6].TBLVAL) - Number(response.balanceDetail.head[15].TBLVAL))   */            
              if (userDataRole.role === 'CUST') {
            desserts.value = [
              {
                name: 'Bakiye',
                tutar: response.balanceDetail.head[0].TBLVAL,
              },
            
            ]
          }
          else{
            desserts.value = [
              {
                name: 'Bakiye',
                tutar: response.balanceDetail.head[0].TBLVAL,
              },
              {
                name: 'Faturalanmamış Sipariş',
                tutar: response.balanceDetail.head[1].TBLVAL,
              },
             /*  {
                name: 'Kendi Çeki/Senedi',
                tutar: response.balanceDetail.head[3].TBLVAL,
              }, */
              {
                name: 'Çek/Senet Toplamı',
                tutar: response.balanceDetail.head[10].TBLVAL,
              },
              {
                name: 'Toplam Risk',
                tutar: response.balanceDetail.head[15].TBLVAL,
              },

              {
                name: 'Risk Limiti',
                tutar: response.balanceDetail.head[6].TBLVAL,
              },
              {
                name: 'Kalan Açık Tutar',
                tutar: Number(response.balanceDetail.head[6].TBLVAL) - Number(response.balanceDetail.head[15].TBLVAL),
              },
            ]
          }
        

            pageLoading.value = false
          }
        })
        .catch(error => {
          if (error.response?.status === 404) {
            userData.value = {}
          }
        })
    }
    const sleep = ms =>
      new Promise(resolve => {
        setTimeout(resolve, ms)
      })
    const whileStop = ref(false)

    const orderStatus = ref()
    const paymentErrorMsg = ref(null)
    const paymentError = ref(false)
    const ltext = ref(''); 
    var iframe
    const submit = async () => {

      console.log("ltext: ",ltext.value )     

      const payload = {
        method: 'addPayment',
        tutar: toplamOdenecekTutar.value,
        paymentType: 1,
        kk_no: kredikartino.value.replaceAll(' ', '') || '4506347048543223',
        kk_expire: kredikartiay.value + '/' + kredikartiyil.value.slice(-2) || '12/30',
        kk_adsoyad: kredikartiadsoyad.value || 'mehmet tali',
        kk_cvv: kredikarticvv.value || '000',
        selectedInstallment: kredikartiinstallment.value?.[taksitSecim.value]?.id || '130',
        bankaKodu: bankaKodu.value || '0015',
        cardFamily: cardFamily.value || 'World',
        cardBankName: cardBankName.value || 'Vakıfbank',
        paymentMethod: 2,
        paymentDiffMode: 1,
        cardType: 1,
        calcedPrice: '',
        ltext: ltext.value || '',
      } 
      //console.log(kredikartiinstallment.value?.[taksitSecim.value]?.id)

      if (!form.value.validate()) {
        return
      }
      paymentErrorMsg.value = ''
      paymentError.value = false

      loading.value = true

      postData(payload).then(async response => {
        if (response.error === false) {
          dialog.value = true
          iframe = document.createElement('iframe')
          iframe.width = '500'
          iframe.height = '500'
          iframe.srcdoc = response.html

          document.getElementById('credit').appendChild(iframe)
          // const { refNo } = response
          // const method = 'paymentCheck'
          // const veri = {
          //   method,
          //   refNo,
          // }
          // while (true) {
          //   postData(veri).then(result => {
          //     if (result.error === 0) {
          //       // BAŞARALI SAYFASI
          //       orderStatus.value = 0
          //       whileStop.value = true
          //     } else if (result.error === 1) {
          //       // BAŞARISIZ SAYFASI
          //       whileStop.value = true
          //       paymentErrorMsg.value = result.msg
          //       orderStatus.value = 1
          //     }
          //   })

          //   if (whileStop.value) {
          //     if (orderStatus.value === 0) {
          //       Vue.swal({ icon: 'success', text: 'Ödemeniz Alındı' })
          //       router.push({ name: 'credit-card-transactions' })
          //     } else if (orderStatus.value === 1) {
          //       paymentError.value = true
          //       dialog.value = false
          //     }

          //     break
          //   }
          //   // eslint-disable-next-line no-await-in-loop
          //   await sleep(500000000000000)
          // }
          // iframe.remove()
          // whileStop.value = false
          // orderStatus.value = null
        } else {
          paymentErrorMsg.value = response.msg
          paymentError.value = true
          Vue.swal({
            title: 'Hata',
            html: response.msg,
            icon: 'error',
            background: '#FF4C51',
            timerProgressBar: true,
            showConfirmButton: false,
          })
        }
      })
    }
    const paymentCancel = () => {
      orderStatus.value = null
      paymentErrorMsg.value = null
      paymentError.value = false
      loading.value = false
      iframe.remove()
      whileStop.value = true
      dialog.value = false
    }
    return {
      paymentCancel,
      paymentErrorMsg,
      paymentError,
      submit,
      form,
      dialog,
      checkbox1,
      checkbox2,
      checkbox3,
      toplamOdenecekTutar,
      taksitlendirme,
      taksitSecim,
      valid,
      kkAdSoyad,
      kkAy,
      kkYil,
      kkCvv,
      kkNo,
      kredikartino,
      kredikartiadsoyad,
      kredikartiay,
      kredikartiyil,
      kredikarticvv,
      kredikartiinstallment,
      selectedInstallment,
      bankaKodu,
      cardFamily,
      cardBankName,
      taksitTutar,
      taksitSayisi,
      menuIsVerticalNavMini,
      userData,
      userDataRole,
      status,
      loading,
      pageLoading,
      desserts,
      odemeTipi,
      taksitDialog,
      currentTab,
      taksitHeaders,
      taksitItems,
      openTaksit,
      ltext,
      icons: {
        mdiContactlessPayment,
        mdiAccountCircle,
        mdiLock,
        mdiPhone,
        mdiAt,
        mdiCalendar,
        mdiUpdate,
      },
    }
  },
  data() {
    return {
      manuelTutar: null,
      
      headers: [
        {
          text: 'Ad',
          align: 'right',
          sortable: false,
          value: 'name',
        },

        {
          text: 'Değer',
          sortable: false,
          align: 'right',
          value: 'tutar',
        },
      ],

      formData: {
        cardName: '',
        cardNumber: '',
        cardMonth: '',
        cardYear: '',
        cardCvv: '',
      },
    }
  },

  watch: {
    manuelTutar(val) {
      this.toplamOdenecekTutar = val
    },
    taksitSecim(val) {
      debugger
      this.toplamOdenecekTutar = this.taksitlendirme.installment[val].inst_tot_amount

      this.taksitTutar = this.taksitlendirme.installment[val].inst_amount
      this.taksitSayisi = this.taksitlendirme.installment[val].installment_text.charAt(0)
    },
  },
  methods: {
    row_classes(item) {      
        if (item.posname == "Akbank ile Axess") {
          return 'akbank';
        } 
        if (item.posname == "Garanti ile Bonus") {
          return 'garanti';
        } 
        if (item.posname == "İş Bankası ile Maximum") {
          return 'isBankasi';
        } 
        if (item.posname == "Yapıkredi ile World") {
          return 'ykb';
        } 
        if (item.posname == "Ziraat Bankası") {
          return 'ziraat';
        } 
        if (item.posname == "Halkbank ile Paraf") {
          return 'halkbank';
        } 
      },
   
    updateCardNumber(val) {
      if (val.length > 18) {
        debugger
        const payload = {
          method: 'getInstallmentsByBin',
          kk_no: val.replaceAll(' ', ''),
          price: this.toplamOdenecekTutar,
        }
        this.$store.dispatch('app-order/fetchBinList', payload).then(response => {
          if (response.error) {
            debugger
            Vue.swal({
              title: 'Hata',
              text: response.msg,
              icon: 'error',
              timer: 2000,
              background: '#FF4C51',
              timerProgressBar: true,
              showConfirmButton: false,
            })
          } else {
          debugger
          this.taksitlendirme = response
          this.cardBankName = response.card_bank_name
          this.cardFamily = response.card_family
          this.bankaKodu = response.bank_code
          this.kredikartiinstallment = response.installment
          }
        })

        this.kkNo = val
        this.kredikartino = val
      } else {
        this.taksitlendirme = {}
      }
    },
    updateCardName(val) {
      this.kkAdSoyad = val
      this.kredikartiadsoyad = val
    },
    updateCardMonth(val) {
      this.kkAy = val
      this.kredikartiay = val
    },
    updateCardYear(val) {
      this.kkYil = val
      this.kredikartiyil = val
    },
    updateCardCvv(val) {
      this.kkCvv = val
      this.kredikarticvv = val
    },
  },
}
</script>

<style lang="scss">


.akbank {
  background-color: #6b61a8;
}
.garanti {
  background-color: #564ba0;
}
.isBankasi {
  background-color: #6885cf;
}
.ykb {
  background-color: #7668cf;
}
.ziraat {
  background-color: #5e99d4;
}
.halkbank {
  background-color: #466a95;
}

.scrollable-textarea .v-input__control {
  max-height: 80px; /* Maksimum yüksekliği belirleyin */
  
}

.textarea-info {
  margin-top: 8px; /* Textarea ile bilgi arasında boşluk */
  font-size: 14px; /* Yazı boyutunu küçültmek için */
  color: #6c757d; /* Varsayılan gri renk */
}

.textarea-counter {
  text-align: right;
  font-size: 0.875rem;
  color: white; /* Counter için hafif gri ton */
  margin-top: 5px; /* İstenirse biraz yukarı çekilebilir */
}

.error-message {
  color: red; /* Hata mesajı kırmızı görünür */
  margin-top: 10px; /* Sayaç ile hata mesajı arasında boşluk */
}
</style>
  

